<template>
  <b-field>
    <b-input
        v-if="type==='filter'"
        v-model="searchTerm"
        v-debounce:300ms="getFiltered"
        :icon-right="$tc('icons.close-circle')"
        :icon-right-clickable="true"
        :loading="loading"
        :required="required"
        placeholder="Lesson Plan"
        v-on:icon-right-click="clear();getFiltered('')"
    ></b-input>
    <b-autocomplete
        v-if="type==='autocomplete'"
        ref="autocomplete"
        v-model="searchTerm"
        v-debounce:300ms="getFiltered"
        :clear-on-select="false"
        :custom-formatter="thing=> name(thing)"
        :data="searchResponse"
        :loading="loading"
        :required="required"
        autocomplete="off"
        @blur="checkSelected()"
        placeholder="Lesson Plan"
        @select="option => select(option)"
    >
      <template slot-scope="props">
        <p>{{ props.option.id }} | {{ props.option.name }}</p>
      </template>
      <template #empty>
        No results
      </template>
    </b-autocomplete>
  </b-field>
</template>

<script>
import LessonPlan from "@/models/LessonPlan";

export default {
  name: "LessonPlansFilter",
  data: function () {
    return {
      loading: false,
      searchTerm: '',
      searchResponse: null
    };
  },
  props: {

    selected_lesson_plan_id: {
      type: Number, required: false
    },
    required: {
      type: Boolean, required: false,
      default(){
        return false
      }
    },
    mode: {
      type: String, default() {
        return 'insertOrUpdate'
      }
    },
    type: {
      type: String, default() {
        return 'filter'
      }
    },
    year: {
      type: Number,
      default() {
        return this.$store.state.dates.year
      }
    },
    campus_id: {
      type: Number, default() {
        return null
      }
    }, subject_id: {
      type: Number, default() {
        return null
      }
    }, course_scope_id: {
      type: Number, default() {
        return null
      }
    }, class_group_id: {
      type: Number, default() {
        return null
      }
    },
    stage_id: {
      type: Number, default() {
        return null;
      }
    }
  },
  watch: {
    selected_lesson_plan_id(newValue) {
      if (newValue !== null) {
        LessonPlan.FetchById(this.selected_lesson_plan_id).then(({entities}) => {
          this.searchResponse = entities.lessonPlans
          this.$refs.autocomplete.setSelected(entities.lessonPlans[0])

        })
      }
    }
  },
  mounted() {
    if (this.selected_lesson_plan_id) {
      LessonPlan.FetchById(this.selected_lesson_plan_id).then(({entities}) => {
        this.searchResponse = entities.lessonPlans
        this.$refs.autocomplete.setSelected(entities.lessonPlans[0])

      })
    }
  },
  methods: {
    checkSelected(){
      if (this.$refs.autocomplete.selected===null){
        this.$refs.autocomplete.newValue=null
      }
    },
    name(thing) {
      return `${thing.id} | ${thing.name}`
    },
    select(option) {
      if (option === null) {
        this.clear()
      } else {
        this.$emit('selected', option)
      }
    },

    clear() {

      this.searchTerm = ''
      this.searchResponse = [];
      this.$emit('cleared')
    },
    getFiltered(text) {
      this.loading = true;
      LessonPlan.FetchAll({page: 1, limit: 999}, {
        ...{search: text},
        ...(this.campus_id ? {
          campus_id: this.campus_id
        } : {}),
        ...(this.class_group_id ? {
          class_group_id: this.class_group_id
        } : {}),
        ...(this.subject_id ? {
          subject_id: this.subject_id
        } : {}),    ...(this.course_scope_id ? {
          course_scope_id: this.course_scope_id
        } : {}),

      }, [],[] ,this.mode).then((result) => {
        this.loading = false;

        if (result.entities) {
          this.searchResponse = result.entities.lessonPlans;
          if (this.type === 'filter') {
            this.$emit('filtered', {result: result, term: this.searchTerm})
          }
        } else {
          this.$emit('filtered', {term: this.searchTerm})

          this.searchResponse = [];
        }
      });

    }
  }
}
</script>

<style scoped>

</style>
